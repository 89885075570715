<template>
  <div class="flow-item-class flex-class">
    <div class="flex-class p" v-for="(item, index) in list" :key="index">
      <div class="x" @click="del(item)">
        <i class="el-icon-close"></i>
      </div>
      <div class="flex-class">
        <div v-if="index != 0 && item.nodeType != 2" class="line-class">
          <i class="el-icon-caret-right"></i>
        </div>
        <div
          v-if="index != 0 && item.nodeType == 2"
          class="line-class"
          @click="addItem"
        >
          <div class="add-icon">+</div>
          <i class="el-icon-caret-right"></i>
        </div>
        <div
          class="item-class"
          @click="editClick(index, item)"
          :style="{ background: item.nodeType !== 3 ? '#f59a23' : '#3370ff' }"
        >
          <div class="item-title-class">{{ item.nodeName }}</div>
          <div class="item-content-class">
            <span
              v-for="(i, key) in item.nodeUsers"
              :key="key"
              style="padding-left: 6px; font-size: 14px"
              >{{ i.userName }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="节点信息"
      :visible.sync="editDialogVisible"
      @close="cancel('form')"
    >
      <el-form
        label-width="120px"
        :model="itemForm"
        :rules="rules"
        class="form-class"
        ref="form"
      >
        <el-form-item label="节点名称：" prop="nodeType">
          <el-select v-model="itemForm.nodeType" placeholder="请选择">
            <el-option label="申请人" :value="1" />
            <el-option label="处理人" :value="2" />
            <el-option label="抄送人" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="节点处理人" prop="nodeSelcteds">
          <el-select
            v-model="itemForm.nodeSelcteds"
            multiple
            placeholder="请选择"
            ref="selectRef"
            @change="roleNameChange"
          >
            <el-option
              v-for="item in userOptions"
              :key="item.id"
              :label="item.name"
              :value="item.userCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择方式" prop="selectType">
          <el-select v-model="itemForm.selectType" placeholder="请选择">
            <!-- <el-option label="指定部门" :value="1" /> -->
            <el-option label="指定成员" :value="2" />
            <!-- <el-option label="所有人" :value="3" /> -->
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="warn-class">
            <div
              @click="warnClick"
              :class="
                itemForm.overtimeWarning ? 'select-class' : 'unSelect-class'
              "
            >
              {{ itemForm.overtimeWarning ? "√" : "" }}
            </div>
            <div class="text-class">超时预警</div>
          </div>
        </el-form-item>
        <el-form-item label="预警提前天数" prop="earlyWarningdays">
          <el-input
            type="number"
            v-model="itemForm.earlyWarningdays"
          ></el-input>
        </el-form-item>
        <div class="button-class">
          <el-form-item>
            <el-button type="primary" @click="cancel('form')">取消</el-button>
            <el-button type="primary" @click="submitForm('form')"
              >保存</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      typeof: Array,
      default: []
    },
    currentBranch: {
      typeof: Number,
      default: null
    }
  },
  data() {
    return {
      pointData: [],
      editDialogVisible: false, //编辑节点弹框
      itemForm: {
        nodeUsers: [],
        overtimeWarning: 0,
        nodeSelcteds: [],
        selectType: 2
      },
      rules: {
        nodeType: [{ required: true, message: "节点名称不能为空" }],
        nodeSelcteds: [{ required: true, message: "节点处理人不能为空" }],
        selectType: [{ required: true, message: "方式不能为空" }],
        earlyWarningdays: [{ required: true, message: "预警提前天数不能为空" }]
      },
      currentIndex: null,
      userOptions: [],
      value: [],
      useList: []
    };
  },
  created() {
    this.useList = this.list;
    this.pointData = this.list;
    this.getUserInfo();
    console.log("this.nodeSelcteds", this.list);
  },
  // mounted() {

  // },
  methods: {
    roleNameChange(val) {
      this.$forceUpdate();
      this.$nextTick(() => {
        // 确保DOM更新后执行强制验证
        this.$refs.form.clearValidate("nodeSelcteds");
        // this.$refs.form.validate('nodeSelcteds')
      });
    },
    addItem() {
      this.pointData.splice(this.pointData.length - 1, 0, {
        nodeType: 2,
        nodeName: "处理人"
      });
    },
    editClick(index, item) {
      console.log(item.nodeType, 162);
      this.currentIndex = index;
      this.itemForm = JSON.parse(JSON.stringify(item));
      this.itemForm.nodeSelcteds = [];
      this.itemForm.selectType = 2;
      // this.itemForm.nodeUsers = []
      if (item.nodeUsers) {
        // console.log("点击this.itemForm.nodeSelcteds", this.itemForm.nodeSelcteds);

        item.nodeUsers.map(element => {
          this.$set(
            this.itemForm.nodeSelcteds,
            this.itemForm.nodeSelcteds.length,
            { ...element }.userCode
          );
          // this.itemForm.nodeUsers.push({ ...element }.userCode)
        });
      } else {
        this.$nextTick(() => {
          this.$refs.form.clearValidate("nodeSelcteds");
        });
        // this.$set(this.itemForm, this.itemForm.nodeSelcteds.length);
      }
      // console.log("item.nodeUsers", item);
      this.editDialogVisible = true;
    },
    warnClick() {
      if (this.itemForm.overtimeWarning === 0) {
        this.itemForm.overtimeWarning = 1;
      } else {
        this.itemForm.overtimeWarning = 0;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 节点类型
          if (this.itemForm.nodeType == 1) {
            this.itemForm.nodeName = "申请人";
          } else if (this.itemForm.nodeType == 2) {
            this.itemForm.nodeName = "处理人";
          } else {
            this.itemForm.nodeName = "抄送人";
          }

          // console.log('kfbcjsfjfcb');
          this.itemForm.nodeUsers = [];
          // 人员数据
          this.userOptions.forEach(element => {
            this.itemForm.nodeSelcteds.forEach((item, index) => {
              if (element.userCode === item) {
                // console.log(this.itemForm.nodeUsers);
                this.$set(this.itemForm.nodeUsers, index, {
                  userCode: element.userCode,
                  userName: element.name,
                  nodeType: this.itemForm.nodeType,
                  nodeSelectType: this.itemForm.selectType
                });
              }
            });
          });
          console.log(274676568);

          this.pointData[this.currentIndex] = {
            ...this.pointData[this.currentIndex],
            ...this.itemForm
          };
          this.cancel("form");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.itemForm = {
        nodeUsers: []
      };
      this.editDialogVisible = false;
      this.$emit("submitItem", this.pointData, this.currentBranch);
    },
    getUserInfo() {
      this.$http.post("engineeringDept/queryDeptAndEmployee", {}).then(res => {
        if (res.data.code == 200) {
          this.userOptions = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    del(row) {
      this.$emit("deleteFlowItem", row, this.currentBranch);
      // this.list = this.list.filter(item=>item.id !=row.id)
    }
  }
};
</script>
<style scoped lang="scss">
.flow-item-class {
  .item-class {
    width: 130px;
    height: 149px;
    padding: 2px 2px 2px 2px;
    border-radius: 10px;
    border: 1px solid #a9b4cd;
    background-color: #a9b4cd;
    box-sizing: border-box;

    .item-title-class {
      font-family: "Arial", sans-serif;
      color: #ffffff;
    }

    .item-content-class {
      // padding: 10px;
      height: 70%;
      border-radius: 5px;
      border: 1px solid #ffffff;
      background-color: #ffffff;
      overflow: hidden;
      display: -webkit-box; /* 使用flexible box model */
      -webkit-box-orient: vertical; /* 垂直方向 */
      -webkit-line-clamp: 4; /* 显示的行数 */
    }
  }

  .flex-class {
    display: flex;
    align-items: center;
  }

  .column-line-class {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .line-class {
    width: 60px;
    height: 1px;
    background: #ffffff;
    position: relative;

    .add-icon {
      position: absolute;
      top: -10px;
      left: 20px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ffffff;
      color: #00b2e6;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /deep/ .el-icon-caret-right:before {
      content: "\e791";
      color: #fff;
      position: absolute;
      top: -5px;
      right: -2px;
    }
  }

  .column-class {
    width: 1px;
    height: 200px;
    background: #fff;
  }
}

// 弹框表单样式
.form-class {
  .button-class {
    display: flex;
    justify-content: flex-end;
  }

  .warn-class {
    display: flex;
    align-items: center;

    .select-class {
      width: 20px;
      height: 20px;
      padding: 2px 2px 2px 2px;
      border-radius: 2px;
      border: 1px solid #02a7f0;
      background-color: #02a7f0;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #ffffff;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unSelect-class {
      width: 20px;
      height: 20px;
      padding: 2px 2px 2px 2px;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.41);
      font-size: 13px;
    }

    .text-class {
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #333333;
      font-size: 15px;
      margin-left: 10px;
    }
  }

  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 150px;
  }

  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
  }
}

.x {
  position: absolute;
  color: #fff;
  right: -10px;
  top: -13px;
  font-size: 20px;
  cursor: pointer;
}

.p {
  position: relative;
}
</style>

<template>
  <div class="body">
    <div class="head-class">
      <div style="display: flex; align-items: center">
        <i class="el-icon-arrow-left" @click="back"></i>
        <div>未命名工单流</div>
      </div>
      <div
        class="head-center"
        @click="flowClick(1)"
        :style="{ borderBottom: isSelect == 1 ? '1px solid #3370ff' : '' }"
      >
        <div :class="isSelect == 1 ? 'selected-class' : 'unSelected-class'">
          1
        </div>
        <div>基础信息</div>
      </div>
      <div
        v-if="$route.query.index == 2"
        class="head-center"
        @click="flowClick(2)"
        :style="{ borderBottom: isSelect == 2 ? '1px solid #3370ff' : '' }"
      >
        <div :class="isSelect == 2 ? 'selected-class' : 'unSelected-class'">
          2
        </div>
        <div>流程设计</div>
      </div>
      <el-button type="primary" @click="submitForm('form')">保存</el-button>
    </div>
    <div class="content-class1" v-if="isSelect === 1">
      <el-form label-width="100px" :model="itemForm" :rules="rules" ref="form">
        <el-form-item label="图标" required prop="logo">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
          >
            <img
              v-if="itemForm.logo"
              :src="itemForm.logo"
              class="avatar"
              style="width: 100px; height: 100px"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="workOrderName">
          <el-input v-model="itemForm.workOrderName"></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="descInfo">
          <el-input v-model="itemForm.descInfo"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="workOrderType">
          <el-select v-model="workOrderType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="isSelect === 2" class="content-class2">
      <WorkOrderFlow
        @submitItem="submitItem"
        :workOrderNo="workOrderNo"
        ref="WorkOrderFlowRef"
      />
    </div>
  </div>
</template>
<script>
import WorkOrderFlow from "./components/workOrderFlow.vue";
export default {
  components: {
    WorkOrderFlow,
  },
  created() {
    this.powerStationCode = localStorage.getItem("powerStationCode");
  },
  mounted() {
    // console.log('创建工单流程', this.$route.query)
    if (this.$route.query.listItem) {
      console.log(this.$route.query.listItem, 88);
      this.itemForm = this.$route.query.listItem;
      this.workOrderNo = this.$route.query.listItem.workOrderNo;
      this.workOrderType = this.$route.query.listItem.workOrderType;
      console.log("this.itemForm", this.itemForm);
    }
  },
  data() {
    return {
      isSelect: 1, //1基础信息 2流程设计
      rules: {
        workOrderName: [{ required: true, message: "名称不能为空" }],
        // descInfo: [ { required: true, message: '图标不能为空', trigger: 'blur' }]
      },

      branchParams: {},
      powerStationCode: "",
      workOrderType: "",
      workOrderNo:"",
      options: [
        {
          label: "消缺",
          value: 1,
        },
        {
          label: "清洗",
          value: 2,
        },
        {
          label: "检修",
          value: 3,
        },
        {
          label: "抢修",
          value: 4,
        },
        {
          label: "其他",
          value: 5,
        },
      ],
      itemForm: {
        id:-1
      },
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    flowClick(val) {
      console.log("当前页", val);
      this.isSelect = val;
    },
    //图片上传成功处理
    handleUploadSuccess(res, file) {
      this.itemForm.logo = file.response.data.url;
    },
    submitForm(formName) {

      if (this.isSelect === 1) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              powerStationCode: this.powerStationCode,
              createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
              createName: JSON.parse(localStorage.getItem("userInfo")).name,
              ...this.itemForm,
              workOrderType: this.workOrderType,
            };
            if (this.$route.query.listItem) {
              params.id = this.$route.query.listItem.id;

              this.$http.post("WorkOrder/updateWork", params).then((res) => {
                if (res.data.code === 200) {
                  this.$notify.success({
                    title: "提示",
                    message: "修改成功",
                    showClose: true,
                  });
                  this.back();
                } else {
                  this.$message.error(res.data.message);
                }
              });
            } else {
              this.$http.post("WorkOrder/createWork", params).then((res) => {
                if (res.data.code === 200) {
                  this.$notify.success({
                    title: "提示",
                    message: "添加成功",
                    showClose: true,
                  });
                  this.back();
                } else {
                  this.$message.error(res.data.message);
                }
              });
            }
          } else {
            alert("请补充完整");
            return false;
          }
        });
      } else {
        this.branchParams.workOrderNo = this.itemForm.workOrderNo;
        this.branchParams.powerStationCode = this.powerStationCode;
        this.branchParams.createCode = JSON.parse(  localStorage.getItem("userInfo")).userCode;
        this.branchParams.createName = JSON.parse(  localStorage.getItem("userInfo")).name;
        this.branchParams.flowWorkOrderBranchList = this.$refs.WorkOrderFlowRef.flowWorkOrderBranchList;

        this.$http
          .post("WorkOrder/createWorkOrderBranch", this.branchParams)
          .then((res) => {
            if (res.data.code === 200) {
              this.$notify.success({
                title: "提示",
                message: "保存成功",
                showClose: true,
              });
              this.back();
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    submitItem(params) {
      this.branchParams = params;
      console.log("liuchfenzhi", this.branchParams);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getDetail() {},
  },
};
</script>

<style lang="scss" scoped>
.body {
  background: #223f6c;
  width: 100%;
  height: 92.8vh;

  .head-class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 73px;
    background: #21527e;
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    font-size: 17px;
    padding: 0 20px;

    .head-center {
      display: flex;
      align-items: center;
      height: 50px;

      .selected-class {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #3370ff;
        border-radius: 50%;
        margin-right: 5px;
        font-size: 13px;
      }

      .unSelected-class {
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 5px;
        border: 1px solid #ffffff;
        font-size: 13px;
      }
    }
  }

  .content-class1 {
    height: 90%;
    margin: 20px;
    background: #21527e;
    padding: 20px 100px 0;
    display: flex;
  }

  .content-class2 {
    margin: 20px;
    height: 85%;
    background: #132c45;
    display: flex;
    align-items: center;
    padding: 10px;
  }
}

/deep/ .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

/deep/ .el-form-item__label {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  font-size: 15px;
  width: 150px;
}

/deep/ .el-input__inner {
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #ffffff;
  font-size: 13px;
}

/deep/ .el-input__inner {
  border-radius: 5px;
  border: 1px solid rgba(151, 179, 203, 0.55);
  background-color: rgba(156, 117, 117, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #f2f2f2;
  text-align: left;
  line-height: normal;
}
</style>

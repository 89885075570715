<template>
  <div class="flow-class">
    <div class="item-class">
      <div class="item-title-class">提交</div>
      <div class="item-content-class">
        <div>创建人：{{ itemForm.createName }}</div>
        <div>可设置抄送人</div>
      </div>
    </div>
    <div class="line-class" @click="addBranch">
      <div class="add-icon">+</div>
    </div>
    <div class="flex-class">
      <div
        v-if="flowWorkOrderBranchList.length !== 1"
        class="column-class"
        :style="{ height: `${129 * flowWorkOrderBranchList.length}px` }"
      ></div>
      <div
        v-if="flowWorkOrderBranchList.length !== 1"
        :style="{ height: `${129 * flowWorkOrderBranchList.length}px` }"
        class="column-line-class p"
      >
        <div
          class="line-class"
          v-for="(i, index) in flowWorkOrderBranchList"
          :key="index"
        >
          <i class="el-icon-delete del" @click="handelDelete(index)"></i>
        </div>
      </div>
      <i v-else class="el-icon-caret-right"></i>
      <div
        :style="{
          height:
            flowWorkOrderBranchList.length !== 1
              ? `${179 * flowWorkOrderBranchList.length}px`
              : ''
        }"
        class="column-line-class"
      >
        <WorkOrderFlowItem
          v-for="(item, index) in flowWorkOrderBranchList"
          :key="index"
          :list="item.flowWorkOrderNodes"
          :currentBranch="index"
          @submitItem="submitItem"
          @deleteFlowItem="deleteFlowItem"
        />
      </div>
    </div>
    <el-dialog
      title="节点信息"
      :visible.sync="editDialogVisible"
      @close="cancel('form')"
    >
      <el-form
        label-width="120px"
        :model="itemForm"
        :rules="rules"
        class="form-class"
        ref="form"
      >
        <el-form-item label="创建人" prop="createCode">
          <el-select v-model="itemForm.createName" placeholder="请选择">
            <!-- <el-option
              v-for="item in userOptions"
              :key="item.id"
              :label="item.name"
              :value="item.userCode"
            >
            </el-option> -->
          </el-select>
        </el-form-item>
        <div class="button-class">
          <el-form-item>
            <el-button type="primary" @click="cancel('form')">取消</el-button>
            <el-button type="primary" @click="addCreateUser('form')"
              >保存</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import WorkOrderFlowItem from "./workOrderFlowItem.vue";
export default {
  props: {
    workOrderNo: {
      typeof: String,
      default: ""
    }
  },
  components: {
    WorkOrderFlowItem
  },
  mounted() {
    this.getUserInfo();
    this.getBranchDetail();
    this.getDetial();
  },
  data() {
    return {
      flowWorkOrderBranchList: [],
      itemForm: {
        createCode: "",
        createName: ""
      },
      rules: {
        createCode: [{ required: true, message: "创建人不能为空" }]
      },
      userOptions: [],
      editDialogVisible: false
    };
  },
  created() {
    this.itemForm.createCode = JSON.parse(
      localStorage.getItem("userInfo")
    ).userCode;
    this.itemForm.createName = JSON.parse(
      localStorage.getItem("userInfo")
    ).name;
  },
  methods: {
    // 添加分支
    addBranch() {
      this.$set(
        this.flowWorkOrderBranchList,
        this.flowWorkOrderBranchList.length,
        {
          flowWorkOrderNodes: [
            {
              nodeType: 1,
              nodeName: "申请人",
              overtimeWarning: 0
            },
            {
              nodeType: 2,
              nodeName: "处理人",
              overtimeWarning: 0
            },
            {
              nodeType: 3,
              nodeName: "抄送人",
              overtimeWarning: 0
            }
          ]
        }
      );
      // this.$store.commit('workOrderflow/setFlowWorkOrderBranchList', this.flowWorkOrderBranchList )
      // console.log(this.flowWorkOrderBranchList,140);
    },
    // 删除分支
    handelDelete(index) {
      this.flowWorkOrderBranchList.splice(index, 1);
    },

    deleteFlowItem(item, index) {
      console.log(item.nodeType);
      if (item.nodeType == 1) {
        return this.$message.info("非法操作!");
      }
      if (item.nodeType == 2) {
        let arr = [];
        this.flowWorkOrderBranchList[index].flowWorkOrderNodes.forEach(
          (ele, index) => {
            ele.nodeType == 2 ? arr.push(ele.nodeType) : "";
          }
        );
        console.log(arr, 158);
        if (arr.length < 2) {
          return this.$message.info("处理人不能少于1个!");
        }
      }
      if (item.nodeType == 3) {
        return this.$message.info("抄送人不能删除!");
      }
      const nodeIndex = this.flowWorkOrderBranchList[
        index
      ].flowWorkOrderNodes.indexOf(item);
      if (nodeIndex !== -1) {
        this.flowWorkOrderBranchList[index].flowWorkOrderNodes.splice(
          nodeIndex,
          1
        );
      }
    },
    // 保存item
    submitItem(list, index) {
      this.flowWorkOrderBranchList[index].flowWorkOrderNodes = list;
      this.flowWorkOrderBranchList[index].branchName = index;
      this.submit();
    },
    // 提交
    submit() {
      let params = {
        createCode: this.itemForm.createCode,
        createName: this.itemForm.createName,
        flowWorkOrderBranchList: this.flowWorkOrderBranchList
      };
      this.$emit("submitItem", params);
    },
    addCreateUser(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.userOptions.forEach(element => {
            if (element.userCode === this.itemForm.createCode) {
              this.itemForm.createName = element.name;
            }
          });
        }
        this.cancel();
      });
    },
    getUserInfo() {
      this.$http.post("engineeringDept/queryDeptAndEmployee", {}).then(res => {
        if (res.data.code == 200) {
          this.userOptions = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getBranchDetail() {
      this.$http
        .post("WorkOrder/getWorkOrderBranch", { workOrderNo: this.workOrderNo })
        .then(res => {
          if (res.data.code === 200) {
            if (res.data.data.length > 0) {
              this.flowWorkOrderBranchList = res.data.data;
              // this.itemForm.createCode = this.flowWorkOrderBranchList[0].flowWorkOrderNodes[0].createCode;
              // this.itemForm.createName = this.flowWorkOrderBranchList[0].flowWorkOrderNodes[0].createName;
            }
            // console.log("flowWorkOrderBranchList", res.data.data);
          }
        });
    },
    async getDetial() {
      let res = await this.$http.post("WorkOrder/queryOne", {
        workOrderNo: this.workOrderNo
      });
      if (res.data.code == 200) {
        this.itemForm.createCode = res.data.data.createCode;
        this.itemForm.createName = res.data.data.createName;
      }
    },
    cancel() {
      this.editDialogVisible = false;
      this.submit();
    }
  }
};
</script>
<style scoped lang="scss">
.form-class {
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 150px;
  }

  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
  }
}

.flow-class {
  display: flex;
  align-items: center;

  .item-class {
    width: 130px;
    height: 149px;
    padding: 2px 2px 2px 2px;
    border-radius: 10px;
    border: 1px solid #a9b4cd;
    background-color: #a9b4cd;
    box-sizing: border-box;

    .item-title-class {
      font-family: "Arial", sans-serif;
      color: #ffffff;
    }

    .item-content-class {
      padding: 10px;
      height: 70%;
      border-radius: 5px;
      border: 1px solid #ffffff;
      background-color: #ffffff;
    }
  }

  .flex-class {
    display: flex;
    align-items: center;
  }

  .column-line-class {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .line-class {
    width: 60px;
    height: 1px;
    background: #ffffff;
    position: relative;

    .add-icon {
      position: absolute;
      top: -10px;
      left: 20px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #ffffff;
      color: #00b2e6;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    /deep/ .el-icon-caret-right:before {
      content: "\e791";
      color: #fff;
      position: absolute;
      top: -5px;
      right: -2px;
    }
  }

  /deep/ .el-icon-caret-right:before {
    content: "\e791";
    color: #fff;
  }

  .column-class {
    width: 1px;
    height: 200px;
    background: #fff;
  }
}

.del {
  color: #fff;
  position: absolute;
  left: 20px;
  top: -15px;
  width: 30px;
  height: 30px;
  background-color: #b4d5ff;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background-color: #f56c6c;
  }
}
.p {
  position: relative;
}
</style>
